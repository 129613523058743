@import "../Base/Variables";

.navbar-container {
  width: 100vw;
  position: fixed;
  z-index: 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  backdrop-filter: blur(0.5px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0%), rgba(255, 255, 255, 1) 80%);
}

.navbar {
  width: 100%;
}

.nav-name-container {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
}

#nav-name {
  font-family: 'Peggs';
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1px;
  text-decoration: none;
  color: $orange;
  transition: $transition-50;
}

.nav-btns-list {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nav-btn {
  font-family: "Relative";
  font-weight: 700;
  line-height: 100%;
  color: #242424;
}

.nav-link-btn {
  // button 
  background: none;
  border-radius: 100px;
  border: 1px solid rgba(0, 0, 0, 0);
  transition: $transition-25;

  &:hover {
    border: 1px solid #B2AFA9;
    transition: $transition-25;
  }
}

.nav-btn-selected {
  color: #ffffff;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  background: radial-gradient(circle, rgba(0,0,0,0.6) 0%, rgba(0,0,0,.45) 75%, rgba(0,0,0,.25) 100%);

  &:hover { border: 1px solid rgba(0, 0, 0, 0) }
}

.comms-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-comm-btn {
  transition: $transition-25;
  &:hover {
    cursor: pointer;
    transition: $transition-25;
  }
}

.nav-comm-btn img {
  // transition: $transition-25;
  opacity: 0.86;
}

#email-link:hover { transform: scale(1.05) rotate(12deg) }
#twitter-link:hover { transform: scale(1.05) rotate(6deg) }
#ig-link:hover { transform: scale(1.05) rotate(-6deg) }
#resume-link:hover { transform: scale(1.05) rotate(-20deg) }

/* ---------- Responsive Web Adjustments ---------- */

/* Mobile ------ */
@media only screen and (min-width: 20rem) {

  #nav-comms-list {
    display: none;
  }

  #about-comms-list {
    display: flex;
    margin-top: 1.5rem;
  }
  
  .navbar-container {
    padding: 1rem 1.5rem;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
  }

  .nav-name-container span {
    display: none;
  }

  #nav-name {
    font-size: 1.25rem;
  }

  .nav-btn {
    font-size: 1rem;
  }

  .nav-link-btn {
    padding: 8px 12px 9px;
    margin: 0px 4px 0px;
  }

  .nav-comm-btn {
    height: 32px;
    width: 40px;
    margin: 0px 4px 0px;
  
      img {
        width: 28px;
        padding: 4px;
      }
  }
}
  

/* Tablets and small desktops ------ */
@media only screen and (min-width: 50rem) {

  #nav-comms-list {
    display: flex;
  }

  #about-comms-list {
    display: none;
  }

  .navbar-container {
    display: flex;
    padding: 1.5rem;
  }

  .navbar {
    display: grid;
    grid-template-columns: [first] 150px [line1] auto [line2] 150px [end];  
  }

  .nav-name-container span {
    display: inline;
  }

  #nav-name { font-size: 1rem }

  .nav-btn { font-size: 0.75rem }

  .nav-link-btn {
    padding: 8px 12px 8px;
  }

  .nav-comm-btn {
    display: block;
    height: 26px;
    width: 32px;
    margin: 0px 8px 0px;
  
    img {
      width: 26px;
      padding: 4px;
    }
  }
}
  
  
/* Large desktops ------ */
@media only screen and (min-width: 75rem) {
  .navbar-container {
    padding: 0.5rem 0rem 0.5rem;
  }

  .navbar {
    width: 1150px;
    padding: 24px 0px 24px;
    grid-template-columns: [first] 250px [line1] auto [line2] 250 [end];  
  }

  #nav-name { 
    font-size: 1.5rem
  }

  .nav-btn {
    font-size: 1rem;
  }

  .nav-comm-btn {
    display: block;
    height: 28px;
    width: 32px;
    margin: 0px 12px 0px;
  
    img {
      width: 28px;
      padding: 4px;
    }
  }

}

/* ---------- End of Responsive Web Section ---------- */

