@import "../Base/Variables";

/* 
  For All Sizes ---------------------------------------
*/


// Applied ---------------

#applied-splash-0 {
  transform: scale(1.0) rotateX(8deg) rotate(-4deg);
  border-radius: 0px;

  &:hover { 
    transform: scale(1.05) rotateX(2deg) rotate(-2deg);
    transition: $ease-out-25;
  }
}

// Strange ---------------

#strange-splash-img-container {
  perspective-origin: top;
}

#strange-splash-0 {
  transform: scale(1.0) rotateX(20deg) rotate(-8deg);
}

// Popper ---------------

#popper-splash-0 {
  position: relative;
  transform: scale(1.0) rotateX(12deg) rotate(10deg);
  transition: $transition-50;
  filter: $reg-shadow-1 $reg-shadow-2 $reg-shadow-3 $reg-shadow-4
              drop-shadow(8px $hard-shadow-depth 0px $hard-shadow-color);

  &:hover {
    transform: scale(1.05) rotateX(4deg) rotate(5deg);
    filter: $hov-shadow-1 $hov-shadow-2 $hov-shadow-3 $hov-shadow-4 !important;
    transition: $ease-out-25;
  }
}

#popper-splash-0:hover {
  z-index: 2;
}

// Elios ---------------

#elios-project-app #top-back-btn-container {
  display: none;
}

/* 
  Mobile ---------------------------------------------
*/

@media only screen and (min-width: 20rem) {

  // Priv ---------------

  #priv-splash-img-container {
    flex: 0 0 2rem !important;
  }

  // Applied ---------------

  #applied-splash-img-container {
    display: flex;
    justify-content: center;
    overflow-y: visible;
    margin-bottom: 2rem;
  }
  
  #applied-splash-0 {
    width: 95%;
  }

  // Strange Machines -----------------

  #strange-splash-img-container {
    display: flex;
    justify-content: center;
    overflow-y: visible;
    flex: 0 0 250px;
  }

  #strange-splash-0 {
    position: absolute;
    height: 200px;
    transform-origin: center center;
  }

  .strange-slider-item, .strange-asset {
    width: 100% !important;
    border-radius: 4px !important; 
  }

    // Popper -----------------

    #popper-splash-img-container {
      width: fit-content;
      flex: 0 0 200px;
      align-items: center;
      justify-content: center;
    }
  
    #popper-splash-0 {
      top: 24px;
      width: 120px;
    }

}

/* Large Mobile ------ */
@media only screen and (min-width: 27rem) { 

    // Applied ---------------
    #applied-splash-img-container {
      flex: 1 1 280px;
      margin-bottom: 0;
    }

    #applied-splash-0 {
      width: 100%;
    }

    #popper-splash-0 {
      top: 0;
      width: 160px;
    }
}

/* Tablets and small desktops ------ */
@media only screen and (min-width: 50rem) {

  // Priv ---------------

  #priv-splash-img-container {
    display: none;
  }

  // Applied ---------------

  #applied-splash-img-container {
    flex: 1 1 420px;
    align-items: center;
  }
  
  #applied-splash-0 {
    position: absolute;
    top: 0;
    width: 95%;
  }

  .applied-slider-item, .applied-asset {
    width: 540px !important;
  }

  // Popper -------------------------

  .popper-slider-item, .popper-asset {
    width: 500px !important;
    // height: fit-content !important;
  }

  // Strange Machines ---------------

  #strange-splash-img-container {
    flex: 0 0 350px;
    overflow-y: visible;
  }

  #strange-splash-0 {
    position: absolute;
    top: -10%;
    height: 360px;
    transform: scale(1.0) rotateX(32deg) rotate(-6deg);
    transform-origin: center bottom;
    &:hover { transform: scale(1.05) rotateX(20deg) rotate(-4deg) }
  }

  .strange-slider-item, .strange-asset {
    width: 540px !important;
  }

  .strange-asset {
    height: 100%;
  }

  .strange-slider-item {
    height: fit-content !important;
    border-radius: 8px !important; 
  }
}

/* Large desktops ------ */
@media only screen and (min-width: 60rem) {

  // Priv ---------------

  #priv-project-section-content {
    width: 100%;
  }

  // Applied ---------------

  #applied-project-section-content {
    width: 55%;
  }

  #applied-splash-img-container {
    flex: 1 1 240px;
  }
  
  #applied-splash-0 {
    position: static;
    width: 95%;
    margin-top: 0rem;
  }

  // Popper ---------------------------

  #popper-splash-img-container {
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
  }

  #popper-splash-0 {
    left: 24px;
    position: relative;
  }

  .popper-slider-item, .popper-asset {
    width: 540px !important;
  }

  // Strange Machines -----------------

  #strange-project-section-content {
    width: 60%;
  }
  
  #strange-splash-img-container {
    flex: 1 1 200px;
  }
  
  #strange-splash-0 {
    position: static;
    width: 420px;
    margin-top: 5rem;
  }

  .strange-slider-item, .strange-asset {
    width: 640px !important;
  }

  // Elios -----------------

  #elios-project-section-content {
    width: 820px;
  }
}

/* 
Large desktops --------------------------------------- 
*/

@media only screen and (min-width: 75rem) {

  // Priv ---------------------

  #priv-project-section-content {
    width: 100%;
  }

  #priv-project-app .project-section-text {
    width: 100%;

    .section-copy {
      width: 800px;
    }
  }

  #priv-splash-img-container {
    height: 0;
  }


  // ABP ---------------------

  #abp-project-section-content {
    width: 100%;
  }

  #abp-project-app .project-section-text {
    width: 100%;

    .section-copy {
      width: 800px;
    }
  }

  .abp-slider-item, .abp-asset {
    width: 880px !important;
  }

  // Applied -----------------

  #applied-project-section-content {
    width: 680px;
  }
  
  #applied-splash-img-container {
    flex: 1 1 300px;
  }
  
  #applied-splash-0 {
    position: absolute;
    top: 1.25rem;
    right: 0;
    width: 105%;
    border-radius: 0px;
    margin-top: 2rem;
    
  }

  .applied-slider-item, .applied-asset {
    width: 720px !important;
  }

  // Strange Machines -----------------

  #strange-project-section-content {
    width: 680px;
  }
  
  #strange-splash-img-container {
    flex: 1 1 300px;
  }
  
  #strange-splash-0 {
    position: absolute;
    top: 0;
    right: -24px;
    width: 560px;
  }

  .strange-slider-item, .strange-asset {
    border-radius: 8px !important; 
    width: 800px !important;
  }

  // Popper -----------------

  #popper-project-section-content {
    width: 720px;
  }

  #popper-splash-img-container {
    flex-direction: row;
    justify-content: center; 
    align-items: center;
  }

  #popper-splash-0 {
    position: relative;
    width: 200px;
  }

  .popper-slider-item, .popper-asset {
    width: 580px !important;
  }
}