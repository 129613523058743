@import "../Base/Variables";

/* ---------- Global Declarations ---------- */

// Global Colors
h1 { color: $orange }
p { color: $header-black }

/* App ------ */

.intro-app {
  width: 100%;
  overflow: hidden;  
  display: flex;
  justify-content: space-between;
  overscroll-behavior-y: none;
}

/* Name Title ------ */
.name-container {
  position: fixed;
  
  .name-letter-l { display: inline-block }
  .name-letter-e { display: inline-block }
}

/* Phrase Section ------ */
.phrase-list-container {
  width: 100%;
  // flex: 2 0 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.phrase-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.phrase-layout { position: relative }

.intro-icon { position: absolute }

.phrase-growing-container { transition: $transition-25 }
#FB-icon { animation: rotation 25s infinite linear }
#Build-icon { animation: step-rotation 4s infinite step-start }
#Story-icon { animation: wiggle 2s infinite ease-in-out }
#Child-icon { animation: tada 2s infinite linear }

.down-btn {
  border: none;
  border-radius: 100px;
  background: none;
  flex-shrink: 0;
  text-decoration: none;
  
  &:hover {
    background: $header-black;
    img { filter: brightness(0) invert(1) }
  }

  &:visited {
    background: $header-black;
  }
}

/* Phrase States ------ */
.phrase-hidden {
  opacity: 0;
  transition: $transition-25;
}

.phrase-focus {
  p, img, button { opacity: 1 }

  img { transform: scale(1.1) }

  p, img { transition: $transition-25 }
  .down-btn > img { transition: $transition-1 }
}

.phrase-unfocus {
  p, img { opacity: 10%; }
  button { opacity: 0% }

  img { transform: scale(1) }  

  p, img { transition: $transition-25 }
  .down-btn > img { transition: $transition-1 }
}



/* ---------- Responsive Web Adjustments ---------- */

/* Mobile ------ */
@media only screen and (min-width: 20rem) {
  
  .intro-app {
    padding: 1.5rem; // 24px
    padding-bottom: 8rem;
    height: 100vh;
    // min-height: fill-available;
    min-height: stretch;
  }

  .inner-intro-app {
    min-height: 100vh;
    // min-height: fill-available;
    min-height: stretch;
  }

  // Typography
  h1 { font-size: 3rem }
  p { font-size: 3rem }
  .name-letter-l { margin-right: -4px }
  .name-letter-e { margin-right: 1px } 

  // Phrase Section
  .phrase-list-container {
    justify-content: end;
  }

  .phrase-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .phrase-layout {
    margin-bottom: 0.5rem;
    flex: 1 1 100%;
    width: 100%;
  }

  .phrase-growing-container {
    height: calc(9 * 1.5rem);
  }

  .down-btn {
    width: 3.75rem; 
    height: 3.75rem;
    border: 2px solid $header-black;

    &:focus { outline:0 }
    &:visited { outline:0 }

    img { width: 1rem; }
  }

  #FB-icon {
    width: 100px;
    right: 0px;
    bottom: -44px;
  }
  
  #Build-icon {
    width: 112px;
    right: 24px;
    bottom: -56px;
  }
  
  #Story-icon {
    width: 96px;
    right: -12px;
    top: 8px;
  }
  
  #Child-icon {
    width: 96px;
    right: 16px;
    bottom: -24px;
  }
}

/* Mobile (2 Line Descriptions) ------ */

@media only screen and (min-width: 29.375rem) {
  .phrase-growing-container {
    height: calc(6 * 1.5rem);
  }
}

/* Tablets and small desktops ------ */
@media only screen and (min-width: 50rem) {

  .intro-app {
    padding: 2.5rem 3rem 2.5rem 2.5rem; // 40px 48px 40px 40px
    height: 100vh;
  }

  // Typography
  h1, p { font-size: 3rem } 
  .name-letter-l { margin-right: -4px }
  .name-letter-e { margin-right: 2px }

  p { text-align: left }

  // Phrase Section
  .phrase-list-container {
    justify-content: flex-end;
  }

  .phrase-container {
    flex-direction: row;
    align-items: flex-start;
  }

  .phrase-layout {
    margin-bottom: 1.5rem; //
    width: 30rem;
  }
  
  .phrase-growing-container {
    // height: calc(5 * 1.5rem);
    height: 7.5rem;
  }

  .down-btn {
    width: 4rem; // 88px
    height: 4rem; // 88px
    border: 4px solid $header-black;

    img { width: 1.75rem; }
  }

  #FB-icon {
    width: 108px;
    right: -8px;
    bottom: -40px;
  }
  
  #Build-icon {
    width: 96px;
    left: -68px;
    top: -56px;
  }
  
  #Story-icon {
    width: 112px;
    right: 8px;
    top: -48px;
  }
  
  #Child-icon {
    width: 120px;
    left: -112px;
    top: 6px;
  }
}


/* Large desktops ------ */
@media only screen and (min-width: 75rem) {

  .intro-app {
    flex-direction: row;
    padding: 2.5rem 3rem 2.5rem 2.5rem; // 40px 48px 40px 40px
  }

  // Typography
  h1, p { font-size: 4.5rem } 

  // Phrase Section
  .phrase-layout {
    margin-bottom: 2.25rem; // 40px
    width: 40rem; // 640px
  }

  .phrase-growing-container {
    // height: calc(5 * 2.25rem); 
    height: 11.25rem; 
  }

  .down-btn {
    width: 5.5rem; // 88px
    height: 5.5rem; // 88px
    border: 4px solid $header-black;

    img { width: 2.25rem; }
  }

  #FB-icon {
    width: 156px;
    right: -12px;
    bottom: -64px;
  }
  
  #Build-icon {
    width: 140px;
    left: -112px;
    top: -64px;
  }
  
  #Story-icon {
    width: 150px;
    right: -24px;
    top: -80px;
  }
  
  #Child-icon {
    width: 190px;
    left: -200px;
    top: 12px;
  }
}


