@import "../Base/Variables";

.about-app {
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

#about-body-container {
  min-height: 100vh;
}

.about-title-section {
  flex: 0 0 auto;
  width: 100%;

  #chinese-title {
    font-family: "Peggs";
    font-weight: 400;
    line-height: 125%;
    color: $header-black;
  }

  .about-title {
    font-family: "Peggs";
    font-weight: 500;
    line-height: 125%;
    letter-spacing: -1px;
    color: $header-black;
  }
}

#about-title-2nd-row {
  display: flex;
  flex-direction: row;
}

.about-p {
  font-family: 'Relative';
  font-weight: 400;
  color: $content-black;
  &:last-child { margin-bottom: 0 }
}

.about-layout-container {
  display: flex;
  flex: 1 0 100%;
  align-items: center;
}

.profile-img {
  background-image: url('../../assets/images/profile.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 160%;
  filter: $pp-shadow;
}

.p-contains-tooltip {
  display: inline-flex;
  flex-direction: row;
}

.contains-tooltip > u {
  &:hover { cursor: help }
}


/* ---------- Responsive Web Adjustments ---------- */

/* Mobile ------ */
@media only screen and (min-width: 20rem) {

  .about-app {
    padding: 0 1.5rem 0;
  }

  #about-body-container {
    padding: 4rem 0 4rem;
  }

  .about-title-section {
    margin: 0px 0px 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    #chinese-title, .about-title {
      font-size: 1.75rem;
    }
  }

  #name-tooltip-container {
    height: 1.75rem;
    width: 6rem;
  }

  .about-p {
    text-align: center;
    max-width: 500px;
    font-size: 1.5rem;
    line-height: 2rem;
    margin-bottom: 1.5rem;
  }

  .about-layout-container {
    width: 100%;
    flex-direction: column;
  
    .about-left-column {
      width: 100%;
      order: 2; // change to bottom
    }
  
    .about-right-column {
      order: 1; // change to top
      width: 100%;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .about-body-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-img {
    width: 100%;
    padding-top: 140%;
  }

  .p-contains-tooltip {
    display: inline-flex;
    width: fit-content;
    text-align: center;
    justify-content: center;
  }

  .contains-tooltip > u {
    margin: 0;
    border-bottom: 2px dotted #646464;
  }
  
  .p-contains-tooltip { 
    height: 1.5rem; 
  }
  
  .p-tooltip-container { width: 4.25rem }
}

/* Large Mobile ------ */
@media only screen and (min-width: 27rem) {

  #about-body-container {
    width: 24rem;
    padding: 4rem 0 4rem;
  }

  .profile-img {
    width: 90%;
  }

  .about-right-column {
    height: fit-content;
  }
  

  .about-p { max-width: 580px }

  .p-tooltip-container { width: 4.25rem }

}
	
/* Tablets and small desktops ------ */
@media only screen and (min-width: 50rem) {

  .about-app {
    padding: 0 1.5rem 0;
  }

  #about-body-container {
    width: 100%;
    padding: 5rem 0 5rem;
  }

  .about-title-section {
    display: block;
    
    #chinese-title, .about-title {
      font-size: 1.75rem;
    }
  }

  #name-tooltip-container {
    width: 6rem;
  }

  .about-body-section {
    align-items: flex-start;
  }

  .about-p {
    font-size: 1.25rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    text-align: left;
  }

  .about-layout-container {
    flex-direction: row;
  
    .about-left-column {
      order: 1;
      flex: 1 0 200px;
      margin-right: 40px;
      margin-bottom: 2rem;
    }
  
    .about-right-column {
      order: 2;
      flex: 0 0 40%;
      display: flex;
      justify-content: flex-end;
      padding-right: 1rem;
    }
  }

  .profile-img {
    width: 100%;
    padding-top: 160%;
    transform: rotate(2deg);
  }

  .p-contains-tooltip {
    text-align: left;
    justify-content: flex-start;
  }
}
	
	
/* Large desktops ------ */
@media only screen and (min-width: 75rem) {

  .about-app {
    padding: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #about-body-container {
    width: 1150px;
  }

  .about-title-section {
    margin: 0px 0px 2rem;
  
    #chinese-title, .about-title {
      font-size: 2.5rem;
    }
  }

  #about-title-2nd-row {
    height: 3.125rem;
  }

  .about-p {
    max-width: 640px;
    font-size: 1.75rem;
    line-height: 2.25rem;
    margin-bottom: 2rem;
  }

  .about-layout-container {
    flex: 1 0 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 64px 0px 64px;
  
    .about-left-column {
      flex: 1 0 200px;
      margin-right: 80px;
    }
  
    .about-right-column {
      flex: 0 0 400px;
    }
  }

  .profile-img {
    width: 420px;
  }

  .contains-tooltip > u {
    margin: 0 4px 0;
    border-bottom: 4px dotted #646464;
  }
  
  #name-tooltip-container {
    height: 50px;
    width: 140px;
  }
  
  .p-contains-tooltip {
    width: 40px;
  }
  
  .p-tooltip-container { width: 82px }
  
}

/* ---------- End of Responsive Web Section ---------- */