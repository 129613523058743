@import "./Variables";

/* Relative Font Family --------------------------------- */ 

@font-face { 
	font-family: 'Relative';
	src: url('../../assets/fonts/relative-book.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face { 
	font-family: 'Relative';
	src: url('../../assets/fonts/relative-medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face { 
	font-family: 'Relative';
	src: url('../../assets/fonts/relative-bold.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face { 
	font-family: 'Relative Faux';
	src: url('../../assets/fonts/relative-faux-book.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
}

// /* Peggs Font Family --------------------------------- */ 

@font-face { 
	font-family: 'Peggs';
  src: url('../../assets/fonts/peggs-medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face { 
	font-family: 'Peggs';
	src: url('../../assets/fonts/peggs-regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

/* ------------------------------------------------- */ 

h1 { 
	font-family: 'Peggs';
	font-weight: 500;
	letter-spacing: -1px;
}

p  { 
	font-family: 'Peggs';
	font-weight: 400;
	letter-spacing: 0px;
}

h1, p {
  line-height: 100%;
  margin: 0;
  text-align: left;
}

a { 
	text-decoration: none;
}

u {
  white-space: nowrap;
  text-decoration: none;
	&:hover { animation: minitada 1.2s linear }
}

strong {
	font-family: 'Relative';
	font-weight: 700;
}

h1, h2, h3, p, a, u, strong {
	text-rendering: optimizeLegibility;
	-webkit-font-feature-settings: "kern" 1;
}

/* ---------- Responsive Web Adjustments ---------- */


