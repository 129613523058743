@import "../Base/Variables";

.project-gallery {
  width: 100%;
  display: flex;

  & > div { flex: 1 1 100% }

  & .gallery-header, & .gallery-footer {
    width: fit-content;
  }
}

.slider {
  display: flex;
}

// .gallery-carousel {
//   display: flex;
//   // overflow-x: scroll;
//   // overflow-y: hidden;

//   /* remove scrollbar */
//   scrollbar-width: none; /* Firefox */
//   -ms-overflow-style: none;  /* Internet Explorer 10+ */
//   &::-webkit-scrollbar { /* WebKit */
//     width: 0;
//     height: 0;
//   }
// }

// .gallery-col {
//   display: flex;
//   flex-direction: column;
// }

.slider-asset {
  transition: $transition-10;
}

.gallery-footer {
  color: #656463;
}

/* ---------- Responsive Web Adjustments ---------- */

/* Mobile ------ */
@media only screen and (min-width: 20rem) {
  .project-gallery {
    // width: calc(100% + 1rem);
    width: 100%;

    // & .gallery-header, & .gallery-footer {
    //   // margin-left: 0.5rem;
    // }
  }

  .gallery-footer {
    font-size: 0.75rem;
    line-height: 1rem;
    margin-top: 0.5rem;
  }

  .slider {
    flex-direction: column;

    & > * { margin: 0 }
    & > * { margin-bottom: 0.5rem }
    // & > .slider-item:first-child { margin-top: 1.5rem }
    & > .slider-item:last-child { margin-bottom: 0 }
  }

  .slider-item {
    width: 100%;
  
    .slider-asset {
      width: 100%;
      height: auto; 
      // width: auto;
    }
  }
}

/* Large Mobile ------ */
@media only screen and (min-width: 27rem) { // 432px

}

/* Tablets and small desktops ------ */
@media only screen and (min-width: 50rem) { // 800px

  .project-gallery {
    width: 100vw;
  //   overflow: visible;

    & .gallery-header, & .gallery-footer {
      margin-left: 7rem;
    }
  }

  .slider {
    width: max-content;
    flex-direction: row;
  
    & > * { margin-right: 1.5rem }
    & > .slider-item:first-child { margin-left: 7rem }
    & > .slider-item:last-child { margin-right: 7rem }
  
  }

  .slider-item, .slider-asset {
    width: 500px;
  }

  .gallery-footer {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/* Small desktops ------ */
@media only screen and (min-width: 60rem) {

  .project-gallery {
    & .gallery-header, & .gallery-footer {
      margin-left: 1.5rem;
    }
  }
  

  .slider-wrapper {
    cursor: grab;
    overflow-x: hidden;
    margin: -30px 0px;
    padding: 30px 0px;
  }

  .slider {
    & > * { margin-right: 1.5rem }
    & > .slider-item:first-child { margin-left: 1.5rem }
    & > .slider-item:last-child { margin-right: 1.5rem }
  }

  
  .slider-item, .slider-asset {
    width: 720px;
    height: 100%;
  }

}

/* Large desktops ------ */
@media only screen and (min-width: 75rem) {

  .project-gallery {
    width: 100vw;

    & .gallery-header, & .gallery-footer {
      margin-left: calc(50vw - 575px);
    }
  }

  .slider {
    & > * { margin-right: 1.5rem }
    & > .slider-item:first-child { margin-left: calc(50vw - 575px) }
    & > .slider-item:last-child { margin-right: calc(50vw - 575px) }
  }

  // .gallery-carousel-wrapper {
  //   width: 100%;
  //   overflow: hidden;
  //   background-color: lightblue;
  // }

  // .gallery-carousel {
  //   // width: 100%;
  //   // overflow-x: scroll;
  //   // overflow-y: hidden;
  //   background-color: lightgreen;
  //   // padding: 0 calc(50vw - 575px);
  
  //   & > * { margin-right: 1rem }
  //   & > img:first-child { margin-left: calc(50vw - 575px) }
  //   & > img:last-child { margin-right: calc(50vw - 575px) }
  // }

}

/* ---------- End of Responsive Web Section ---------- */
